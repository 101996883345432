import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../contexts/auth.context";
import { Navigate } from "react-router";
import useForm from "../../hooks/form.hook";
import useApi from "../../hooks/api.hook";
import { Link } from "react-router-dom";
import { passwordTests } from "../../../util/regex.util";
import { onEnterKey } from "../../../util/input.util";
import { VERSION } from "../../../config/config";

export default function Login({defaultRoom}: {
    defaultRoom?: string;
}) {

    const {auth, login, loginGuest} = useContext(AuthContext);
    const api = useApi("");

    const form = useForm({
        roomId: defaultRoom || '',
        username: '',
        password: '',
        guest: false
    });

    const [serverVersion, setServerVersion] = useState('--');

    const LoginGuest = () => {
        const errs: Record<string, string> = {};

        if (/^\s*$/.test(form.roomId)) errs.roomId = "Room ID cannot be blank";
        else if (form.roomId.length < 4) errs.roomId = "Must be at least 4 characters";
        else if (!/^[a-z\d\-]{4,}$/i.test(form.roomId)) errs.roomId = "Can only contain numbers, letters and dashes";

        if (/^\s*$/.test(form.username)) errs.username = "Username cannot be blank";
        else if (form.username.length < 4) errs.username = "Must be at least 4 characters";
        else if (!/^[a-z\d]{4,}$/i.test(form.username)) errs.username = "Can only contain numbers and letters";

        form.setErrors(errs);

        if (Object.keys(errs).length > 0) return;

        form.submit(async () => {
            const err = await loginGuest(form.username, form.roomId);
            
            if (err) form.setError("username", err);

        })
    }

    const Login = () => {
        const errs: Record<string, any> = {};

        if (/^\s*$/.test(form.roomId)) errs.roomId = "Room ID cannot be blank";
        else if (form.roomId.length < 4) errs.roomId = "Must be at least 4 characters";
        else if (!/^[a-z\d\-]{4,}$/i.test(form.roomId)) errs.roomId = "Can only contain numbers, letters and dashes";

        if (/^\s*$/.test(form.username)) errs.username = "Username cannot be blank";
        // else if (form.username.length < 4) errs.username = "Must be at least 4 characters";
        // else if (!/^[a-z\d]+$/i.test(form.username)) errs.username = "Can only contain numbers and letters";

        const tests = passwordTests

        const pwdErrs = [];

        // for (const test of tests) {
        //     if (!test.rgx.test(form.password)) pwdErrs.push(test.text);
        // }

        if (pwdErrs.length > 0) {
            errs.password = pwdErrs
        }

        form.setErrors(errs);

        if (Object.keys(errs).length > 0) return;

        form.submit(async () => {
            try {
                await login(form.username, form.password, form.roomId);
            } catch (error) {
                form.setError("username", `${error}`);
            }

        })
    }

    useEffect(() => {
        api.get("")
            .then(async res => {
                if (!res.ok) return console.error((await res.json()).message);

                setServerVersion(await res.text());
            });
    }, [])

    if (auth.user) return (<Navigate to={"/room"} />);

    return (
        <div className="w-screen h-screen flex items-center justify-center">
            <div className="w-full">
                <div className="text-center border shadow-lg max-w-screen-sm mx-auto text-sm">
                    <img className="block mx-auto w-32" src="/logo192.png" alt="Logo" />
                    <div className="text-left p-4">
                
                        <label htmlFor="iRoomId">Room ID</label>
                            {form.errors.roomId && (
                                <p className="text-red-500 text-xs">{form.errors.roomId}</p>
                            )}
                            <div className="flex items-center mb-4 border">
                                <div className="flex-1">
                                    <input id="iRoomId" disabled={form.loading || defaultRoom != undefined} type="text"
                                    value={form.roomId}
                                    onChange={e => {
                                        form.set({roomId: e.target.value.replaceAll(' ', '-')});
                                        form.setError("roomId", undefined);
                                    }}
                                    className={[
                                        "w-full p-2",
                                        form.errors.roomId ? 'border-red-600' : ''
                                    ].join(" ")} />
                                </div>
                                {defaultRoom != undefined && (
                                    <Link to={'/'}><button className="p-2 px-4"><i className="fas fa-times"></i></button></Link>
                                )}
                            </div>
                
                        <label>Username
                            {form.errors.username && (
                                <p className="text-red-500 text-xs">{form.errors.username}</p>
                            )}
                            <input disabled={form.loading} type="text"
                            value={form.username}
                            onChange={e => {
                                form.set({username: e.target.value});
                                form.setError("username", undefined);
                            }}
                            className={[
                                "w-full border p-2",
                                form.errors.username ? 'border-red-600' : ''
                            ].join(" ")} />
                        </label>
                        {!form.guest && (
                            <label>Password
                                <input disabled={form.loading} type="password"
                                value={form.password}
                                onChange={e => {
                                    form.set({password: e.target.value});
                                    form.setError("password", undefined);
                                }}
                                onKeyDown={e => onEnterKey(e, Login)}
                                className={[
                                    "w-full border p-2",
                                    form.errors.password ? 'border-red-500' : ''
                                ].join(" ")} />
                                <ul className={[
                                    "text-xs text-red-600 list-disc list-inside"
                                ].join(" ")}>
                                    {form.errors.password && form.errors.password.map(v => (
                                        <li key={`pwderr_${v}`}>{v}</li>
                                    ))}
                                </ul>
                            </label>
                        )}
                        <div className="flex items-center gap-4 mt-4">
                            <div className="flex-1">
                                {form.guest ? (
                                    <button disabled={form.loading} onClick={LoginGuest} className="w-full border p-2 bg-green-600 text-white">{form.loadText("Continue as guest")}</button>
                                ) : (
                                    <button disabled={form.loading} onClick={Login} className="w-full border p-2 bg-green-600 text-white">{form.loadText("Sign in")}</button>
                                )}
                            </div>
                            <div>
                                {form.guest ? (
                                    <button disabled={form.loading} onClick={() => form.set({guest: false})} className="text-blue-600 hover:underline">Sign in as user</button>
                                ) : (
                                    <button disabled={form.loading} onClick={() => form.set({guest: true})} className="text-blue-600 hover:underline">Sign in as guest</button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="border rounded-md shadow-lg mt-4 p-4 max-w-screen-sm mx-auto">
                    <p className="text-center">Have a referral code? <Link to={'/register'} className="cursor-pointer text-blue-500 hover:underline">Create an account</Link>.</p>
                </div>

                <p className="text-center mt-4">Server ver. {serverVersion} | Client ver. {VERSION}</p>
            </div>
        </div>
    );
}