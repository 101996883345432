export function CropImageSquare(file: File, callback?: (file:File|Blob, dataUrl: string) => void, size: number = 256) {

    const reader = new FileReader();
    reader.onloadend = e => {
        const res = e.target.result.toString();
        const img: HTMLImageElement = new Image();
        img.onload = () => {

            if (img.width <= size && img.height <= size) return callback(file, res);

            const canvas: HTMLCanvasElement = document.createElement("canvas");
            const ctx: CanvasRenderingContext2D = canvas.getContext("2d");

            if (img.width <= size && img.height > size) {
                //crop to width
                canvas.width = img.width;
                canvas.height = img.width;
                ctx.drawImage(img, 0, img.height/2 - canvas.height/2, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height);
            }

            else if (img.width > size && img.height <= size) {
                //crop to height
                canvas.width = img.height;
                canvas.height = img.height;
                ctx.drawImage(img, img.width/2 - canvas.width/2, 0, canvas.width, canvas.height, 0, 0,  canvas.width, canvas.height);
            } else {
                //crop to which is smaller
                const smaller = img.width < img.height ? img.width : img.height;
                canvas.width = smaller;
                canvas.height = smaller;
                ctx.drawImage(img, img.width/2 - canvas.width/2, img.height/2 - canvas.height/2, canvas.width, canvas.height, 0, 0, canvas.width, canvas.height);

                //resize until it is sizexsize
                const b: HTMLCanvasElement = document.createElement("canvas");
                const btx: CanvasRenderingContext2D = b.getContext("2d");

                while (canvas.width/2 >= size) {
                    b.width = canvas.width/2;
                    b.height = canvas.width/2;
                    btx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, b.width, b.height);
                    canvas.width = b.width;
                    canvas.height = b.height;
                    ctx.drawImage(b, 0, 0);
                }

                b.width = size;
                b.height = size;
                btx.drawImage(canvas, 0, 0, canvas.width, canvas.height, 0, 0, b.width, b.height);
                canvas.width = size;
                canvas.height = size;
                ctx.drawImage(b, 0, 0);
            }

            canvas.toBlob(blob => {
                callback(blob, canvas.toDataURL());
            });

        }
        img.src = res;
    }
    reader.readAsDataURL(file);

}