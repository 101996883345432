import { useEffect, useRef } from "react";
import { clamp01 } from "../../util/math.util";

export default function OpacityInput({value, bgColor, onChange}: {
    value: number;
    bgColor: string;
    onChange: (val: number) => void;
}) {
    const percent = Math.round(value*10000)/100;
    
    const touchRef = useRef<HTMLDivElement>();
    const stateRef = useRef({
        down: false
    });

    const MouseDown = (e: MouseEvent) => {
        stateRef.current.down = true;
    }

    const MouseMove = (e: MouseEvent) => {
        if (!stateRef.current.down) return;
        
        const box = touchRef.current.getBoundingClientRect();
        const pos = {
            x: e.clientX - box.left,
            y: e.clientY - box.top
        }

        onChange(clamp01(pos.x/box.width));
    }

    const MouseUp = (e: MouseEvent) => {
        stateRef.current.down = false;
    }

    useEffect(() => {
        if (!touchRef.current) return;

        const el = touchRef.current;
        el.addEventListener("mousedown", MouseDown)
        el.addEventListener("mouseup", MouseUp)
        el.addEventListener("mousemove", MouseMove)

        return () => {
            el.removeEventListener("mousedown", MouseDown)
            el.removeEventListener("mouseup", MouseUp)
            el.removeEventListener("mousemove", MouseMove)
        }
    }, []);

    return (
        <div style={{background: `url('/transparent_grid.png')`}}>
            <div ref={touchRef} className="w-32 h-8 border relative" style={{
                background: `linear-gradient(90deg, transparent, ${bgColor})`
            }}>
                <div className="h-full w-[3px] bg-white border border-black absolute" style={{left: `${percent}%`}}></div>
            </div>
        </div>
    );
}