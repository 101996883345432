import { Route, Routes } from "react-router";
import Login from "./app/pages/login";
import { BrowserRouter } from "react-router-dom";
import Error from "./app/components/Error";
import Room from "./app/pages/room";
import Register from "./app/pages/register";

export default function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/room/*" element={<Room />} />
          <Route path="/register" element={<Register />} />
          <Route path="*" element={<Error code={404} statusText="Not found" />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}