import './css/Modal.css';

export default function Modal({id, title, onClose, children}: {
    id: string;
    title?: string;
    onClose?: () => void;
    children: React.ReactNode;
}) {
    return (
        <>
        <input type="checkbox" id={id} className="modal-toggle" />
        <div className="modal">
            <div className="modal-box">
                <div className="flex mb-4 items-center">
                    <div className="flex-1">
                        {title != undefined && (<p className="font-bold text-xl">{title}</p>)}
                    </div>

                    <div>
                        <label onClick={onClose} htmlFor={id} className="cursor-pointer"><i className="fas fa-times"></i></label>
                    </div>
                </div>

                {children}
            </div>
        </div>
        </>
    );
}