import { useContext, useEffect, useRef, useState } from "react";
import { IMessage } from "../../type/message.type";
import { AuthContext } from "../contexts/auth.context";
import { UserRank } from "../../enum/user-rank.enum";
import useApi from "../hooks/api.hook";
import { SocketContext } from "../contexts/socket.context";

export default function Message({msg, index, selected, onSelect, onDeselect, onDeleted}: {
    msg: IMessage;
    index: number;
    selected?: boolean;
    onDeselect: () => void;
    onSelect: (message: IMessage, index: number) => void;
    onDeleted?: (id: number) => void;
}) {
    const {auth} = useContext(AuthContext);
    const msgRef = useRef<HTMLDivElement>();
    const api = useApi('');
    const socket = useContext(SocketContext);

    const time = new Date(msg.createdAt);
    let timestamp = time.toDateString().substring(0, time.toDateString().length-5) + ' ' + time.toLocaleTimeString();

    const onContextMenu = (e: MouseEvent) => {
        e.preventDefault();
        onSelect(msg, index);
    }

    const DeleteMsg = async () => {
        // const res = await api.delete(`messages/${msg.id}`);
        // if (!res.ok) return console.error((await res.json()).message);

        if (onDeleted) onDeleted(msg.id);
        socket.send("delete_msg", {msg});
    }

    const DeleteUserMsgs = async () => {
        // const res = await api.delete(`messages/room/${msg.room}/user/${msg.authorId}`);
        // if (!res.ok) return console.error((await res.json()).message);

        if (onDeleted) onDeleted(msg.id);

        if (!msg.authorId) {
            socket.send("delete_ip_msgs", {
                room: msg.room,
                ip: msg.ip
            });
        } else {
            socket.send("delete_users_msgs", {
                room: msg.room,
                userId: msg.authorId
            });
        }
        
    }

    const ClearRoom = async () => {
        // const res = await api.delete(`messages/room/${msg.room}`);
        // if (!res.ok) console.error((await res.json()).message);
        if (onDeleted) onDeleted(msg.id);
        socket.send("clear_room", {room: msg.room});
    }

    useEffect(() => {
        if (!msgRef.current || !auth.user) return;

        if (auth.user.guest === true || auth.user.rank < UserRank.Moderator) return;

        const el = msgRef.current;
        el.addEventListener("contextmenu", onContextMenu);

        return () => {
            el.removeEventListener("contextmenu", onContextMenu);
        }
    }, [auth.user])

    if (!auth.user) return (
        <div className="border-b p-1 flex gap-1">
        </div>
    );
    
    return (
        <div ref={msgRef} className="border-b relative">
            {msg.author ? (
                <div className="relative" style={{
                    backgroundColor: msg.author.style.bgColor
                }}>
                    <div className="absolute w-full h-full" style={{
                        backgroundImage: msg.author.style.bgImage ? `url('${msg.author.style.bgImage}')` : undefined,
                        backgroundPosition: msg.author.style.bgAlign,
                        backgroundSize: msg.author.style.bgSize,
                        backgroundRepeat: msg.author.style.bgRepeat ? 'repeat' : 'no-repeat',
                        opacity: msg.author.style.bgOpacity
                    }}></div>
                    <div className="absolute top-0 right-0 text-right z-[1]" style={{fontSize: '10px'}}>
                        <p><span className="bg-black text-white p-[1px] opacity-25">{timestamp}</span></p>
                        {auth.user?.guest === false && auth.user?.rank >= UserRank.Moderator && (
                            <p><span className="bg-black text-white p-[1px] opacity-25">{msg.ip}</span></p>
                        )}
                    </div>
                    <div className="relative p-1">
                        <div className="avatar"><img src={`/api/v1/users/${msg.author.id}/avatar`} alt={msg.author.username} /></div> <strong style={{color: msg.author.style.nameColor}}>{msg.author.style.displayName || msg.author.username}:</strong> <span dangerouslySetInnerHTML={{__html: msg.content}} style={{color: msg.author.style.fontColor, fontFamily: msg.author.style.fontFamily, fontSize: msg.author.style.fontSize}}></span>
                    </div>
                </div>
            ) : (
                <div className="relative">
                    <div className="absolute top-0 right-0 text-right" style={{fontSize: '9px'}}>
                        <p><span className="bg-black text-white p-[1px] opacity-50">{timestamp}</span></p>
                        {auth.user.guest === false && auth.user.rank >= UserRank.Moderator && (
                            <p><span className="bg-black text-white p-[1px] opacity-50">{msg.ip}</span></p>
                        )}
                    </div>
                    <div className="relative">
                        <span className="p-1" dangerouslySetInnerHTML={{__html: msg.content}}></span>
                    </div>
                </div>
            )}

            {auth.user.guest === false && auth.user.rank >= UserRank.Moderator && selected && msg.id != undefined && (
                <div className="fadeIn absolute top-0 left-0 w-full h-full bg-[#000000A0] text-white overflow-hidden">
                    <div className="flex items-center justify-center h-full gap-2 p-2">
                        <button onClick={DeleteMsg} className="btn bg-white text-black">Delete</button>
                        <button onClick={DeleteUserMsgs} className="btn bg-white text-black">Delete all</button>
                        <button className="btn bg-red-800">Ban user</button>
                        <button onClick={onDeselect} className="btn">Cancel</button>
                    </div>
                </div>
            )}
        </div>
    );
}