export default function Error({code, statusText}: {
    code: string|number;
    statusText?: string;
}) {
    return (
        <div className="Error h-screen flex items-center justify-center">
            <div className="text-center">
                <p className="text-7xl">{code}</p>
                <p className="text-2xl">{statusText}</p>
                <p><button onClick={() => {
                    window.history.back();
                }} className="hover:underline text-blue-500"><i className="fas fa-arrow-left"></i> Back</button></p>
            </div>
        </div>
    );
}