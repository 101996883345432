export const passwordTests = [
    {
        rgx: /.{8,}/,
        text: "Must be at least 8 characters"
    },
    {
        rgx: /[a-z]/,
        text: "Must contain at least 1 lowercase letter"
    },
    {
        rgx: /[A-Z]/,
        text: "Must contain at least 1 uppercase letter"
    },
    {
        rgx: /\d/,
        text: "Must contain at least 1 number"
    },
    {
        rgx: /[!@#$%^&*_+\-=:;"',.]/,
        text: "Must contain at least 1 special character"
    },
];