import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/auth.context";
import useApi from "../hooks/api.hook";
import { IUser } from "../../type/user.type";

export default function ReferralCode() {

    const {auth} = useContext(AuthContext);
    const refService = useApi("referrals/");
    const userService = useApi("users/");

    const [code, setCode] = useState("");
    const [claimees, setClaimees] = useState<IUser[]>(undefined);

    useEffect(() => {
        if (!auth.user || auth.user.guest === true) return;

        const usrId = auth.user.sub;

        refService.get(`${auth.user.sub}`)
            .then(async res => {
                const body = await res.json();

                if (!res.ok) return console.error(body.message);

                setCode(body.code);

                const res2 = await userService.get(`${usrId}/referred`);
                const body2 = await res2.json();

                if (!res2.ok) return console.error(body2.message);

                setClaimees(body2);
            });
    }, [auth.user]);

    return (
        <div>
            <p className="text-xs italic">Referred users {claimees ? claimees.length.toLocaleString() : '--'}/10</p>
            <input readOnly type="text" value={code} className="border p-2 text-center w-full" />
        </div>
    );
}