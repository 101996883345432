import { Navigate, Route, Routes } from "react-router";
import Page from "./room-page";
import Loading from "../../components/Loading";
import { useContext } from "react";
import { AuthContext } from "../../contexts/auth.context";
import { SocketProvider } from "../../contexts/socket.context";

function _Room() {
    const {auth} = useContext(AuthContext);
    if (!auth.token) return (<Navigate to={"/"} />);
    if (auth.user) return (<Navigate to={`/room/${auth.user.roomId}`} />);
    
    return (
        <div className="flex items-center justify-center h-screen">
            <Loading />
        </div>
    );
}

export default function Room() {

    return (
        <SocketProvider>
            <Routes>
                <Route path="/" element={<_Room />} />
                <Route path="/:roomId" element={<Page />} />
            </Routes>
        </SocketProvider>
    );
}