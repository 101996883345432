import { createContext, useContext, useEffect, useRef, useState } from "react";
import { io, Socket } from "socket.io-client";
import { AuthContext } from "./auth.context";

type ISocket = {
    send: (ev: string, data: Record<string, any>) => void;
    on: (ev: string, listener: (...args: any[]) => void) => void;
    off: (ev: string) => void;
    join: (group: string) => void;
    leave: (group: string) => void;
    disconnect: () => void;
}

export const SocketContext = createContext<ISocket>(undefined);

export function SocketProvider({children}: {
    children: React.ReactNode;
}) {
    const auth = useContext(AuthContext);

    const [socket, setSocket] = useState<Socket>(undefined);

    const send = (ev: string, data: Record<string, any>) => {
        if (socket) socket.emit(ev, {
            ...data,
            token: auth.auth.token
        });
    }

    const on = (ev: string, listener: (...args: any[]) => void) => {
        if (socket) socket.on(ev, listener);
    }

    const off = (ev: string) => {
        if (socket) socket.off(ev);
    }

    const join = (group: string) => {
        if (socket) send("join_group", {group});
    }

    const leave = (group: string) => {
        if (socket) send("leave_group", {group});
    }

    const disconnect = () => {
        if (socket) socket.disconnect();
    }

    useEffect(() => {
        
        const sock = io();

        sock.on("end_conn", () => {
            sock.disconnect();
            setSocket(undefined);
            // auth.logout();
        });

        setSocket(sock);

        return () => {
            sock.off("end_conn");
            sock.disconnect();
            setSocket(undefined);
        }
    }, []);

    return (
        <SocketContext.Provider value={{
            send,
            on,
            off,
            join,
            leave,
            disconnect
        }}>
            {children}
        </SocketContext.Provider>
    );
}