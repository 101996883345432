import { createContext, useEffect } from "react";
import useForm from "../hooks/form.hook";
import { IAuthUser, IGuestUser, IUser } from "../../type/user.type";
import Loading from "../components/Loading";

type IAuth = {
    auth: {
        token: string;
        user: IAuthUser|IGuestUser;
    };
    login: (username: string, password: string, roomId: string) => Promise<boolean>;
    loginGuest: (username: string, roomId: string) => Promise<string|undefined>;
    logout: () => void;
}

export const AuthContext = createContext<IAuth>(undefined);

export function AuthContextProvider({children}: {
    children: React.ReactNode;
}) {

    const state = useForm<{
        token: string;
        user: IAuthUser;
    }>({
        token: localStorage.getItem("_bt") || undefined,
        user: undefined
    });

    const login = async (username: string, password: string, roomId: string) => {

        const res = await fetch(`/api/v1/auth/login`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username,
                password,
                roomId
            })
        });

        const body = await res.json();

        if (!res.ok) {
            console.error(body);
            // return false;
            throw new Error(body.message);
        }

        state.set({token: body.token});
        localStorage.setItem("_bt", body.token);
        return true;
    }

    const loginGuest = async (username: string, roomId: string) => {

        const res = await fetch(`/api/v1/auth/loginGuest`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                username,
                roomId
            })
        });

        const body = await res.json();

        if (!res.ok) {
            console.error(body);
            return body.message;
        }

        localStorage.setItem("_bt", body.token);
        state.set({token: body.token});

        return;
        
    }

    const logout = () => {
        localStorage.removeItem("_bt");
        state.set({
            token: undefined,
            user: undefined
        });
    }

    useEffect(() => {
        if (!state.token) return;

        fetch("/api/v1/auth/me", {
            headers: {
                authorization: `Bearer ${state.token}`
            }
        })
            .then(async res => {
                // const body = await res.json();

                if (res.status === 500) {
                    console.log("Failure to connect");
                    return;
                }

                if (!res.ok) {
                    console.error(await res.json());
                    logout();
                    return;
                }

                const body = await res.json();

                state.set({user: body});
                console.log(body);
                
            });
    }, [state.token]);


    return (
        <AuthContext.Provider value={{
            auth: {
                token: state.token,
                user: state.user
            },
            login,
            logout,
            loginGuest
        }}>
            {state.loading ? (
                <Loading />
            ) : children}
        </AuthContext.Provider>
    );
}