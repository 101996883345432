import { useContext } from "react";
import { AuthContext } from "../../contexts/auth.context";
import { Navigate } from "react-router";
import useForm from "../../hooks/form.hook";
import useApi from "../../hooks/api.hook";
import { passwordTests } from "../../../util/regex.util";
import { Link } from "react-router-dom";

export default function Register() {

    const {auth} = useContext(AuthContext);
    const authService = useApi("auth/");

    const form = useForm({
        email: '',
        username: '',
        password: '',
        referralCode: ''
    });

    const CheckEmail = async () => {
        form.setError("email", undefined);

        if (/^\s*$/.test(form.email)) return form.setError("email", "Please enter your email");

        if (!/^[\w\-.]+@[\w\-]+\.[a-z]{2,}$/i.test(form.email)) {
            return form.setError("email", "Invalid Email");
        }

        const res = await authService.get(`checkEmail/${form.email}`);

        if (!res.ok) form.setError("email", (await res.json()).message);
    }

    const CheckUsername = async () => {
        form.setError("username", undefined);

        if (/^\s*$/.test(form.username)) return form.setError("username", "Cannot be blank");

        if (form.username.length < 4) {
            return form.setError("username", "Must be at least 4 characters");
        }
        if (form.username.length > 12) {
            return form.setError("username", "Must be less than 13 characters");
        }

        if (!/^[a-z\d]{4,12}$/i.test(form.username)) {
            return form.setError("username", "Can only contain numbers and letters");
        }

        const res = await authService.get(`checkUser/${form.username}`);

        if (!res.ok) form.setError("username", (await res.json()).message);
    }

    const CheckPwd = () => {
        form.setError("password", undefined);
        const errs = [];
        const tests = passwordTests;

        for (const test of tests) {
            if (!test.rgx.test(form.password)) {
                errs.push(test.text);
            }
        }

        if (errs.length > 0) form.setError("password", errs);
    }

    const Submit = async () => {
        await CheckUsername();
        await CheckEmail();
        CheckPwd();

        if (form.errors.email || form.errors.username || form.errors.password) return;

        form.submit(async () => {
            const res = await authService.post('register', {
                username: form.username,
                email: form.email,
                password: form.password,
                referralCode: form.referralCode
            });
            const body = await res.json();

            if (!res.ok) return form.setError("main", body.message);

            window.location.href = '/';    
        });

        
    }

    if (auth.token) return (<Navigate to={'/'} />);

    return (
        <div className="h-svh grid grid-rows-[auto_1fr]">
            <div>
                <div className="flex items-center">
                    <div className="flex-1">
                        <img src="/logo192.png" alt="Logo" className="w-12" />
                    </div>

                    <div>
                        <p>Have an account? <Link to={'/'} className="text-blue-500 hover:underline">Sign in</Link></p>
                    </div>
                </div>
            </div>

            <div className="flex items-center justify-center h-full overflow-auto">
                <div className="w-full">
                    <div className="border rounded-md shadow-lg max-w-screen-sm mx-auto p-4">
                        <h1 className="font-bold text-xl">Create Account</h1>
                        {form.errors.main && (
                            <p className="text-red-500 text-sm my-2">{form.errors.main}</p>
                        )}
                        <div>
                            <label>Referral Code
                                <input disabled={form.loading} type="text"
                                value={form.referralCode}
                                onChange={e => form.set({referralCode: e.target.value})}
                                className={[
                                    "w-full border p-2",
                                    form.errors.referralCode ? 'border-red-500' : ''
                                ].join(" ")} />
                            </label>
                            <label>Email address
                                <input disabled={form.loading} type="text"
                                value={form.email}
                                onChange={e => form.set({email: e.target.value})}
                                onBlur={CheckEmail}
                                className={[
                                    "w-full border p-2",
                                    form.errors.email ? 'border-red-500' : ''
                                ].join(" ")} />
                            </label>
                            {form.errors.email && (
                                <p className="text-red-500 text-xs">{form.errors.email}</p>
                            )}
                            <label>Username
                                <input disabled={form.loading} type="text"
                                value={form.username}
                                onChange={e => form.set({username: e.target.value})}
                                onBlur={CheckUsername}
                                className={[
                                    "w-full border p-2",
                                    form.errors.username ? 'border-red-500' : ''
                                ].join(" ")} />
                            </label>
                            {form.errors.username && (
                                <p className="text-red-500 text-xs">{form.errors.username}</p>
                            )}
                            <label>Password
                                <input disabled={form.loading} type="password"
                                value={form.password}
                                onChange={e => form.set({password: e.target.value})}
                                onBlur={CheckPwd}
                                className={[
                                    "w-full border p-2",
                                    form.errors.password ? 'border-red-500' : ''
                                ].join(" ")} />
                            </label>
                            {form.errors.password && (
                                <ul className="text-red-500 text-xs list-disc list-inside">{form.errors.password.map(v => (
                                    <li key={`pwderr_${v}`}>{v}</li>
                                ))}</ul>
                            )}
                            <button disabled={form.loading || form.errors.username || form.errors.password || form.errors.email}
                            onClick={Submit}
                            className="btn mt-4 w-full bg-green-600 text-white">{form.loadText('Submit')}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}