import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "../../contexts/auth.context";
import { Navigate, useParams } from "react-router";
import { SocketContext } from "../../contexts/socket.context";
import { IAuthUser, IGuestUser } from "../../../type/user.type";
import useForm from "../../hooks/form.hook";
import './css/index.css'
import Login from "../login";
import StyleEditor from "../../components/StyleEditor";
import { IUserStyle } from "../../../type/user-style.type";
import useApi from "../../hooks/api.hook";
import Modal from "../../components/Modal";
import ReferralCode from "../../components/ReferralCode";
import { IMessage } from "../../../type/message.type";
import { UserRank } from "../../../enum/user-rank.enum";
import Message from "../../components/Message";

export default function Page() {

    const {auth, logout} = useContext(AuthContext);
    const socket = useContext(SocketContext);
    const userService = useApi("users/");
    const msgService = useApi("messages/");

    const params = useParams();

    const [roomId, setRoomId] = useState(params.roomId);
    const [messages, setMessages] = useState<IMessage[]>([]);
    const [showStyleEditor, setShowStyleEditor] = useState(false);
    const [style, setStyle] = useState<IUserStyle>(undefined);
    const [onlineNow, setOnlineNow] = useState<(IAuthUser|IGuestUser)[]>([]);
    const [selectedMsg, setSelectedMsg] = useState<number>(-1);

    const form = useForm({
        message: ''
    });

    const addUser = (user: IAuthUser|IGuestUser) => {
        setOnlineNow(prev => {

            if (prev.map(v => v.username.toLowerCase()).includes(user.username.toLowerCase())) return prev;

            return [...prev, user];
        });
    }

    const removeUser = (user: IAuthUser|IGuestUser) => {
        setOnlineNow(prev => {
            const li: (IAuthUser|IGuestUser)[] = [];

            for (const usr of prev) {
                if (usr.username.toLowerCase() !== user.username.toLowerCase()) li.push({...usr});
            }

            return li;
        });
    }

    const messagesRef = useRef<HTMLDivElement>();

    const addMessage = (message: IMessage) => {
        setMessages(prev => [message, ...prev])
    }

    const deleteMessage = (msg: IMessage) => {
        setMessages(prev => {
            const li: IMessage[] = [];

            for (const _msg of prev) {
                if (_msg.id !== msg.id) li.push({..._msg});
            }

            return li;
        })
    }

    const deleteUserMessages = (id: number) => {
        setMessages(prev => {
            const li: IMessage[] = [];

            for (const msg of prev) {
                if (msg.authorId !== id) li.push({...msg});
            }

            return li;
        })
    }

    const deleteIpMessages = (ip: string) => {
        setMessages(prev => {
            const li: IMessage[] = [];

            for (const msg of prev) {
                if (msg.ip !== ip) li.push({...msg});
            }

            return li;
        })
    }

    const clearRoom = () => {
        setMessages([]);
    }

    const SendMessage = () => {

        if (/^\s*$/.test(form.message)) return form.set({message: ''});

        form.submit(async () => {
            socket.send("room_message", {
                roomId: roomId,
                user: auth.user,
                message: form.message
            });
            form.set({message: ''})
        });
    }

    const SignOut = () => {
        logout();
    }

    useEffect(() => {

        if (!auth.user || auth.user.guest === true) return;

        console.log(auth.user);

        userService.get(auth.user.sub.toString())
            .then(async res => {
                const body = await res.json();

                if (!res.ok) return console.error(body.message);

                setStyle(body.style);
            });
        
    }, [auth.user]);

    useEffect(() => {
        if (selectedMsg > -1) setSelectedMsg(prev => prev+1);
    }, [messages]);

    // useEffect(() => {
    //     if (!messagesRef.current) return;
    //     const el = messagesRef.current;
    //     if (el.scrollHeight - el.clientHeight - el.scrollTop <= 100) {
    //         messagesRef.current.scrollTo(0, el.scrollHeight); 
    //     }
    //     // messagesRef.current.scrollTo(0, box.height);
    // }, [messages, auth.user]);

    useEffect(() => {

        if (!auth.user) return;

        socket.on("user_join", addUser);

        socket.on("user_leave", removeUser);
        // socket.on("user_join", (user: IAuthUser|IGuestUser) => {
        //     // addMessage(`${user.username} has joined`)
        //     addMessage({
        //         id: undefined,
        //         content: `${user.username} has joined`,
        //         room: roomId,
        //         createdAt: new Date().toISOString(),
        //         ip: user.ip
        //     });
        // });

        // socket.on("user_leave", (user: IAuthUser|IGuestUser) => {
        //     addMessage({
        //         id: undefined,
        //         content: `${user.username} has left`,
        //         room: roomId,
        //         createdAt: new Date().toISOString(),
        //         ip: user.ip
        //     });
        // });

        socket.on("room_message", (message: IMessage) => {
            // addMessage(`<strong>${user.username}:</strong> ${message}`);
            addMessage(message);
        })

        socket.on("get_room_user", (user: IAuthUser|IGuestUser) => {
            if (user.username.toLowerCase() === auth.user.username.toLowerCase()) return;
            socket.send("receive_room_user", auth.user);
        });

        socket.on("receive_room_user", (user: IAuthUser|IGuestUser) => {
            if (user.username.toLowerCase() === auth.user.username.toLowerCase()) return;
            addUser(user);
        })

        socket.on("delete_msg", deleteMessage);

        socket.on("delete_users_msgs", deleteUserMessages);

        socket.on("delete_ip_msgs", deleteIpMessages);

        socket.on("clear_room", clearRoom);

        msgService.get(`room/${roomId}/1`)
            .then(async res => {
                const body = await res.json();

                if (!res.ok) return console.error(body.message);

                setMessages(body);

                // setMessages(prev => ([
                //     ...body,
                //     ...prev
                // ]));
            });

        socket.send("join_room", {
            roomId: params.roomId,
            user: auth.user
        });

        socket.send("get_room_users", {
            roomId: params.roomId
        });

        const unloadHandler = () => {
            socket.send("leave_room", {
                roomId: params.roomId,
                user: auth.user
            });

            // socket.disconnect();
        }

        window.addEventListener("beforeunload", unloadHandler);



        return () => {
            setOnlineNow([]);
            socket.send("leave_room", {roomId: params.roomId, user: auth.user})
            socket.off("user_join");
            socket.off("user_leave");
            socket.off("get_room_user");
            socket.off("receive_room_user");
            socket.off("room_message");
            socket.off("delete_msg");
            socket.off("delete_users_msgs");
            socket.off("delete_ip_msgs");
            socket.off("clear_room");
            window.removeEventListener("beforeunload", unloadHandler);
        }
    }, [roomId, auth.user]);

    useEffect(() => {
        document.title = `${params.roomId} - IRC`;
    }, []);

    // if (!auth.user) return (<Navigate to="/" />);

    if (!auth.token) return (
        <Login defaultRoom={params.roomId} />
    );

    return (
        <>
        <div className="h-svh grid grid-rows-[auto_1fr_auto]">
            <div className="border-b">
                <div className="flex items-center gap-2 p-2">
                    <div className="flex-1">
                        <div className="flex items-center gap-2">
                            <div className="avatar"><img src="/logo192.png" alt="Logo" /></div>
                            <p>{roomId}</p>
                        </div>
                    </div>

                    <div>
                        <div className="dropdown dropdown-end">
                            <span tabIndex={0} className="btn"><i className="fas fa-users"></i></span>
                            <div tabIndex={0} className="dropdown-content relative z-[1]">
                                <div className="bg-white border rounded-md shadow-lg p-4 w-80">
                                    <div className="text-xs opacity-50">Online Now</div>
                                    <ul className="menu text-sm">
                                        <div className="overflow-auto max-h-48">
                                            {onlineNow.map((usr, i) => {

                                                const ava = usr.guest === false ? (
                                                    <div className="avatar w-8 rounded-full overflow-hidden border-2">
                                                        <img src={`/api/v1/users/${usr.sub}/avatar`} alt={usr.username} />
                                                    </div>
                                                ) : '';

                                                return (
                                                    <li key={`online_usr_${usr.username}_${i}`}>
                                                        <span>
                                                            {ava} {usr.username}
                                                        </span>
                                                    </li>
                                                );
                                            })}
                                        </div>
                                        <div className="border-b"></div>
                                        {!auth.user?.guest && (<li><label htmlFor="invite_code_modal"><i className="fas fa-user-plus"></i> Invite</label></li>)}
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div ref={messagesRef} className="flex flex-col-reverse overflow-auto max-h-full">
                {messages.map((msg, i) => (
                    <Message key={`msg_${i}_${msg.id}`} msg={msg} index={i} selected={i === selectedMsg}
                    onSelect={(msg, j) => setSelectedMsg(j)}
                    onDeselect={() => setSelectedMsg(-1)}
                    onDeleted={() => setSelectedMsg(-1)}
                    />
                ))}
            </div>

            <div className="p-2 border-t">

                <div className="flex items-center gap-2">
                    <div className="flex-1 relative" style={{backgroundColor: style?.bgColor ?? 'white'}}>
                        <div className="w-full h-full absolute top-0 left-0" style={style ? {
                            backgroundImage: style.bgImage ? `url('${style.bgImage}')` : undefined,
                            backgroundRepeat: style.bgRepeat ? 'repeat' : 'no-repeat',
                            backgroundPosition: style.bgAlign,
                            backgroundSize: style.bgSize || undefined,
                            opacity: style.bgOpacity
                        } : undefined}></div>

                        <input onKeyDown={e => {
                            if (e.key ==="Enter") {
                                SendMessage();
                            }
                        }} type="text"
                        value={form.message}
                        onChange={e => form.set({message: e.target.value})}
                        placeholder="Enter your message and press send"
                        className="border p-2 w-full h-12 px-14 bg-transparent relative" style={style ? {
                            fontSize: style.fontSize,
                            fontFamily: style.fontFamily,
                            color: style.fontColor
                        } : undefined} />
                        
                    </div>

                    <div>
                        <button onClick={SendMessage} className="border p-2 px-4 text-blue-500"><i className="fas fa-paper-plane"></i></button>
                    </div>

                    <div>
                        <div className="dropdown dropdown-end dropdown-top">
                            <button tabIndex={0} className="border p-2 px-4"><i className="fas fa-ellipsis-v"></i></button>
                            <div tabIndex={0} className="dropdown-content bg-white border rounded-md shadow-lg p-4 w-64">
                                <ul className="menu">
                                    {!auth.user?.guest && (<li><label htmlFor="edit_style_modal" onClick={() => setShowStyleEditor(true)}><i className="fas fa-user-edit"></i> Edit styles</label></li>)}
                                    <div className="border-b m-0"></div>
                                    <li><span onClick={SignOut}>Sign out</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div>
                        
                    </div>
                </div>
            </div>
        </div>


        {auth.user?.guest === false && (
            <Modal id="invite_code_modal" title="Referral Code">
                <p className="text-sm">Send your referral code to your friends to allow them to create an account.</p>

                <ReferralCode />
                
                <div className="modal-action">
                    <label htmlFor="invite_code_modal" className="btn">Close</label>
                </div>
            </Modal>
        )}

        <Modal id="edit_style_modal" title="User Styles" onClose={() => setShowStyleEditor(false)}>
            {showStyleEditor && (
                <StyleEditor onUpdate={style => {
                    setShowStyleEditor(false);
                    document.querySelector<HTMLInputElement>("#edit_style_modal").checked = false;
                    setStyle(style);
                }} />
            )}
        </Modal>
        </>
    );
}