import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../contexts/auth.context";
import Loading from "./Loading";
import { IUser } from "../../type/user.type";
import useApi from "../hooks/api.hook";
import useForm from "../hooks/form.hook";
import { IUserStyle } from "../../type/user-style.type";
import { CropImageSquare } from "../../util/image.util";
import './css/style-editor.css';
import OpacityInput from "./OpacityInput";

export default function StyleEditor({onUpdate}: {
    onUpdate?: (style: IUserStyle) => void;
}) {

    const {auth} = useContext(AuthContext);
    const userService = useApi("users/");

    const [user, setUser] = useState<IUser>(undefined);
    const [ava, setAva] = useState(0);

    const form = useForm<Partial<IUserStyle>>({
        displayName: '',
        nameColor: '#000000',
        fontColor: '#000000',
        fontSize: 16,
        bgColor: '#ffffff',
        fontFamily: undefined,
        bgSize: undefined,
        bgAlign: 'left top',
        bgImage: undefined,
        bgOpacity: 1
    });

    const Submit = () => {

        form.submit(async () => {
            if (auth.user.guest === true) return;
            const res = await userService.patch(`${auth.user.sub}/style`, {
                ...form,
                fontSize: Math.max(9, Math.min(24, form.fontSize))
            });
            const body = await res.json();

            if (!res.ok) return console.error(body.message);
            console.log(body);
            if (onUpdate) onUpdate(body);
        });
    }

    const UploadAvatar = () => {
        const input = document.createElement("input");
        input.type = 'file';
        input.accept = 'image/*';
        input.multiple = false;
        input.addEventListener("change", e => {
            //@ts-ignore
            const file: File = e.target.files[0];

            CropImageSquare(file, async (blob, durl) => {
                await SubmitAvatar(blob);
            })
        });
        input.click();
    }

    const SubmitAvatar = async (file: File|Blob) => {
        if (auth.user?.guest === true) return;

        const form = new FormData();
        form.set("file", file);
        const res = await userService.post(`${auth.user?.sub}/avatar`, form);

        if (!res.ok) console.error((await res.json()).message);
        
        setAva(prev => prev+1);
    }

    const UploadBackground = () => {
        const input = document.createElement("input");
        input.type = 'file';
        input.accept = 'image/*';
        input.multiple = false;
        input.addEventListener("change", e => {
            //@ts-ignore
            const file: File = e.target.files[0];

            CropImageSquare(file, async (blob, durl) => {
                await SubmitBackground(blob);
            }, 2048);
        });
        input.click();
    }

    const SubmitBackground = async (file: File|Blob) => {
        if (auth.user?.guest === true) return;

        const _form = new FormData();
        _form.set("file", file);
        const res = await userService.post(`${auth.user?.sub}/background`, _form);
        const body = await res.json();
        if (!res.ok) return console.error(body.message);
        
        setAva(prev => prev+1);
        form.set({bgImage: body.bgImage});
    }

    const ClearBackground = async () => {
        if (auth.user?.guest === true) return;
        const res = await userService.delete(`${auth.user?.sub}/background`);

        if (!res.ok) console.error((await res.json()).message);

        form.set({bgImage: null});
    }

    const SetDisplayName = (value: string) => {
        
        form.setError("name", undefined)
        form.set({displayName: value});

        if (/^\s{1,}$/.test(value)) return form.setError("name", "Name cannot be blank");
        if (value.length > 12) return form.setError("name", "Name must be under 13 characters");


    }

    useEffect(() => {
        if (!user) return;

        const {userId, createdAt, modifiedAt, ...style} = user.style;

        form.set({...style});
    }, [user]);

    useEffect(() => {
        if (auth.user?.guest === true) return;

        userService.get(`${auth.user.sub}`)
            .then(async res => {
                const body = await res.json();

                if (!res.ok) return console.error(body.message);

                setUser(body);
            })

    }, [auth.user])

    if (!auth.user) {
        return (<Loading />);
    }

    if (auth.user.guest === true) return (
        <p>Guests cannot edit their styles</p>
    );

    return (
        <div className="StyleEditor mt-4">
            <p>Preview</p>
            <div className="border relative" style={{
                backgroundColor: form.bgColor,

            }}>
                <div className="absolute top-0 left-0 w-full h-full" style={{
                    backgroundImage: form.bgImage ? `url('${form.bgImage}?${ava}')` : undefined,
                    backgroundPosition: form.bgAlign,
                    backgroundSize: form.bgSize || undefined,
                    backgroundRepeat: form.bgRepeat ? 'repeat' : 'no-repeat',
                    opacity: form.bgOpacity
                }}></div>

                <div className="p-1 relative">
                    <div className="avatar"><img src={`/api/v1/users/${auth.user.sub}/avatar?${ava}`} alt={auth.user.username} /></div> <strong style={{color: form.nameColor}}>{form.displayName || auth.user.username}:</strong> <span style={{
                            color: form.fontColor,
                            fontSize: `${Math.max(9, Math.min(24, form.fontSize))}px`,
                            fontFamily: form.fontFamily
                    }}>The quick brown fox jumps over the lazy dog.</span>
                </div>
            </div>

            <div className="my-4 grid gap-1">
                <div className="flex justify-end">
                    <button onClick={UploadAvatar} className="btn">Upload Avatar</button>
                </div>

                <div className="flex items-center gap-4">
                    <div className="flex-1">
                        <p>Display Name</p>
                    </div>

                    <div>
                        {form.errors.name != undefined && (
                            <p className="text-red-500 text-xs">{form.errors.name}</p>
                        )}
                        <input disabled={form.loading} type="text" value={form.displayName} maxLength={12} onChange={e => SetDisplayName(e.target.value)} className="border px-1" />
                    </div>
                </div>

                <div className="flex items-center">
                    <div className="flex-1">
                        <p>Name Color</p>
                    </div>

                    <div className="flex items-center">
                        <input disabled={form.loading} type="color" value={form.nameColor} onChange={e => form.set({nameColor: e.target.value})} className="w-16" />
                        <input disabled={form.loading} type="text" value={form.nameColor} onChange={e => form.set({nameColor: e.target.value})} className="w-16 border" />
                    </div>
                </div>
                
                <div className="flex items-center">
                    <div className="flex-1">
                        <p>Font Color</p>
                    </div>

                    <div className="flex items-center">
                        <input disabled={form.loading} type="color" value={form.fontColor} onChange={e => form.set({fontColor: e.target.value})} className="w-16" />
                        <input disabled={form.loading} type="text" value={form.fontColor} onChange={e => form.set({fontColor: e.target.value})} className="w-16 border" />
                    </div>
                </div>
                
                <div className="flex items-center">
                    <div className="flex-1">
                        <p>Font Size</p>
                    </div>

                    <div>
                        <input disabled={form.loading} type="number" min={9} max={24} value={form.fontSize} onChange={e => form.set({fontSize: +e.target.value})} className="border w-16" />
                    </div>
                </div>

                <div>
                    <select value={form.fontFamily || '-1'} onChange={e => form.set({fontFamily: e.target.value === '-1' ? null : e.target.value})}
                    className="border w-full">
                        <option value="-1">Default</option>
                        <option value="Arial">Arial</option>
                        <option value="Calibri">Calibri</option>
                        <option value="Cambria">Cambria</option>
                        <option value="Comic Sans MS">Comic Sans MS</option>
                        <option value="Courier New">Courier New</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Impact">Impact</option>
                        <option value="Lucida Console">Lucida Console</option>
                        <option value="monospace">monospace</option>
                        <option value="MS Gothic">MS Gothic</option>
                        <option value="sans-serif">sans-serif</option>
                        <option value="Segoe UI">Segoe UI</option>
                        <option value="Segoe Script">Segoe Script</option>
                        <option value="Symbol">Symbol</option>
                        <option value="Sylfaen">Sylfaen</option>
                        <option value="Times New Roman">Times New Roman</option>
                    </select>
                </div>

                <div className="flex items-center justify-end gap-2">
                    <button onClick={UploadBackground} className="btn">{form.bgImage === null ? 'Upload Background' : 'Change Background'}</button>
                    {form.bgImage !== null && (
                        <button onClick={ClearBackground} className="btn">Clear Background</button>
                    )}
                    
                </div>

                <div>
                    <label className="flex items-center gap-2 justify-end">
                        <input type="checkbox" checked={form.bgRepeat} onChange={e => form.set({bgRepeat: e.target.checked})} />
                        <span>Repeat Background</span>
                    </label>
                </div>

                <div className="flex items-center">
                    <div className="flex-1">
                        <p>Background Color</p>
                    </div>

                    <div className="flex items-center">
                        <input disabled={form.loading} type="color" value={form.bgColor} onChange={e => form.set({bgColor: e.target.value})} className="w-16" />
                        <input disabled={form.loading} type="text" value={form.bgColor} onChange={e => form.set({bgColor: e.target.value})} className="w-16 border" />
                    </div>
                </div>

                <div className="flex items-center">
                    <div className="flex-1">
                        <p>Background Opacity</p>
                    </div>

                    <div>
                        <OpacityInput value={form.bgOpacity} onChange={val => form.set({bgOpacity: val})} bgColor={form.bgColor} />
                    </div>
                </div>

                <div>
                    <select value={form.bgSize}
                    onChange={e => {
                        form.set({bgSize: e.target.value === '-1' ? null : e.target.value})
                    }}
                    className="w-full border">
                        <option value="-1">None</option>
                        <option value="contain">Contain</option>
                        <option value="cover">Cover</option>
                    </select>
                </div>

                <div className="flex items-center">
                    <div className="flex-1">
                        <p>Background Align</p>
                    </div>

                    <div>
                        <div className="grid grid-cols-3">
                            <button onClick={() => form.set({bgAlign: 'left top'})} className={`btn align-btn ${form.bgAlign === 'left top' ? 'active' : ''}`}></button>
                            <button onClick={() => form.set({bgAlign: 'center top'})} className={`btn align-btn ${form.bgAlign === 'center top' ? 'active' : ''}`}></button>
                            <button onClick={() => form.set({bgAlign: 'right top'})} className={`btn align-btn ${form.bgAlign === 'right top' ? 'active' : ''}`}></button>
                            <button onClick={() => form.set({bgAlign: 'left center'})} className={`btn align-btn ${form.bgAlign === 'left center' ? 'active' : ''}`}></button>
                            <button onClick={() => form.set({bgAlign: 'center center'})} className={`btn align-btn ${form.bgAlign === 'center center' ? 'active' : ''}`}></button>
                            <button onClick={() => form.set({bgAlign: 'right center'})} className={`btn align-btn ${form.bgAlign === 'right center' ? 'active' : ''}`}></button>
                            <button onClick={() => form.set({bgAlign: 'left bottom'})} className={`btn align-btn ${form.bgAlign === 'left bottom' ? 'active' : ''}`}></button>
                            <button onClick={() => form.set({bgAlign: 'center bottom'})} className={`btn align-btn ${form.bgAlign === 'center bottom' ? 'active' : ''}`}></button>
                            <button onClick={() => form.set({bgAlign: 'right bottom'})} className={`btn align-btn ${form.bgAlign === 'right bottom' ? 'active' : ''}`}></button>
                        </div>
                    </div>
                </div>

                <div className="flex items-center justify-end my-4">
                    <button disabled={form.loading || form.errors.name != undefined} onClick={Submit} className="border p-2 px-4 bg-green-600 text-white">{form.loadText(<i className="fas fa-save"></i>)} Save</button>
                </div>
            </div>
        </div>
    );
}