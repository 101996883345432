import { useContext, useState } from "react";
import { AuthContext } from "../contexts/auth.context";

type IQueryMethod = "GET"|"POST"|"PATCH"|"PUT"|"DELETE";

type IQueryBody = {
    body: Record<string, any>|FormData;
    method: IQueryMethod;
};

export default function useApi(root: string) {
    root = '/api/v1/' + root;

    const {auth} = useContext(AuthContext);
    
    function query(path: string): Promise<Response>;
    function query(path: string, body: IQueryBody): Promise<Response>;
    function query(body: IQueryBody): Promise<Response>;
    function query(pathOrBody: string|IQueryBody, body?:IQueryBody) {
        
        let path: string;
        let headers: Record<string, any> = {
            authorization: auth.token ? `Bearer ${auth.token}` : undefined
        };
        if (typeof pathOrBody === 'string') {
            path = root + pathOrBody;
        } else {
            path = root;
            body = pathOrBody;
        }

        const bIsFormData = body && body.body ? body.body.toString() === '[object FormData]' : false;

        // headers['Content-Type'] = bIsFormData ? undefined : 'application/json';
        if (!bIsFormData) headers['Content-Type'] = 'application/json';
        

        return fetch(path, {
            method: body?.method || "GET",
            headers,
            body: bIsFormData ? body.body as FormData : JSON.stringify(body.body)
        });
    }

    function get(path: string): Promise<Response>;
    function get(path: string, body: FormData|Record<string, any>): Promise<Response>;
    function get(body: FormData|Record<string, any>): Promise<Response>;
    function get(pathOrBody: string|FormData|Record<string, any>, body?: FormData|Record<string, any>) {
        
        if (typeof pathOrBody === 'string') {
            return query(pathOrBody, {
                body,
                method: "GET"
            })
        } else {
            return query({
                body: body,
                method: "GET"
            })
        }

    }

    function post(path: string): Promise<Response>;
    function post(path: string, body: FormData|Record<string, any>): Promise<Response>;
    function post(body: FormData|Record<string, any>): Promise<Response>;
    function post(pathOrBody: string|FormData|Record<string, any>, body?: FormData|Record<string, any>) {
        
        if (typeof pathOrBody === 'string') {
            return query(pathOrBody, {
                body,
                method: "POST"
            })
        } else {
            return query({
                body: body,
                method: "POST"
            })
        }

    }

    function patch(path: string): Promise<Response>;
    function patch(path: string, body: FormData|Record<string, any>): Promise<Response>;
    function patch(body: FormData|Record<string, any>): Promise<Response>;
    function patch(pathOrBody: string|FormData|Record<string, any>, body?: FormData|Record<string, any>) {
        
        if (typeof pathOrBody === 'string') {
            return query(pathOrBody, {
                body,
                method: "PATCH"
            })
        } else {
            return query({
                body: body,
                method: "PATCH"
            })
        }

    }

    function put(path: string): Promise<Response>;
    function put(path: string, body: FormData|Record<string, any>): Promise<Response>;
    function put(body: FormData|Record<string, any>): Promise<Response>;
    function put(pathOrBody: string|FormData|Record<string, any>, body?: FormData|Record<string, any>) {
        
        if (typeof pathOrBody === 'string') {
            return query(pathOrBody, {
                body,
                method: "PUT"
            })
        } else {
            return query({
                body: body,
                method: "PUT"
            })
        }

    }

    function remove(path: string) {

        return query(path, {
            method: "DELETE",
            body: undefined
        });

    }

    return {
        get,
        post,
        patch,
        put,
        delete: remove
    }
}